import React from 'react'

const Invoice: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="56"
      viewBox="0 0 64 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M57.3636 10.5H6.63636C3.52349 10.5 1 12.4588 1 14.875V41.125C1 43.5412 3.52349 45.5 6.63636 45.5H57.3636C60.4765 45.5 63 43.5412 63 41.125V14.875C63 12.4588 60.4765 10.5 57.3636 10.5Z"
        fill="white"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 17.0625V36.75"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M52.125 17.0625V36.75"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21.9297 17.0625V29.3672"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M32 17.0625V36.75"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M42.0625 17.0625V29.3672"
        stroke="#00CC2C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Invoice
